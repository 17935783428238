:root {
  --body-background: #fff;
  --body-color: #333;
  --heading-color: 333;
  --action-color: rgb(252, 36, 56);
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  margin: 0 8px;
  font-size: 108%;
  line-height: 1.5;
}

a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: #ffb088;
}

h1 {
  margin: 1rem 0 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 2.5rem;
  color: var(--heading-color);
  line-height: 1.2;
}

.container {
}

.container > header {
  margin: 1rem;
  text-align: center;
}

.container > header img {
  display: inline-block;
  width: 200px;
  max-width: 100%;
}

.container > nav ul {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

.container > nav a {
  text-decoration: none;
}

main {
  margin: 2rem auto 4rem;
  max-width: 65rem;
  padding: 25px 25px 50px;

  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
}

footer {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1em;
  position: fixed;
  bottom: 0px;
  width: 100vw;
  text-align: center;
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
}
